import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import 'element-plus/lib/theme-chalk/display.css'
import registerSvgIcon from './icons'
import storage from '@/storage'
import global from '@/utils/global'
const app = createApp(App)
app.use(router)
app.use(ElementPlus)
app.mount('#app')

registerSvgIcon(app)

app.config.globalProperties.STORAGE = storage
app.config.globalProperties.GLOBAL = global
