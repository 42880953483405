import { createRouter, createWebHistory } from 'vue-router'

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Index',
      component: () => import('@/views/Index')
    },
    {
      path: '/employment',
      name: 'Employment',
      component: () => import('@/views/Employment')
    },
    {
      path: '/about_us',
      name: 'AboutUs',
      component: () => import('@/views/AboutUs')
    },
    {
      path: '/registration_process',
      name: 'RegistrationProcess',
      component: () => import('@/views/RegistrationProcess')
    },
    {
      path: '/teacher_team',
      name: 'TeacherTeam',
      component: () => import('@/views/TeacherTeam')
    },
    {
      path: '/course',
      name: 'Course',
      component: () => import('@/views/Course')
    },
    {
      path: '/student_works',
      name: 'StudentWorks',
      component: () => import('@/views/StudentWorks')
    },
    {
      path: '/:catchAll(.*)',
      name: 'NotFound',
      component: () => import('@/views/NotFound')
    }
  ]
})
