<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
#app {
  width: 100%;
  height: auto;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 1.5;
}

* {
  margin: 0;
  padding: 0;
}

ul, li {
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

hr {
  width: 100%;
  border-color: #ccc;
  border-style: solid;
  border-width: 1px 0 0 0;
}

.el-carousel {
  width: 100%;
}

.el-select {
  width: 100%;
}

img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.box, .row {
  width: 100%;
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  max-width: 1200px;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
}

.img-cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .container {
    padding: 0 20px;
  }
}

@media (max-width: 767px) {
  .container {
    padding: 0 10px;
  }
}

.btn-cursor {
  cursor: pointer;
}

p {
  margin: 8px 0;
  word-wrap: break-word;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}
</style>
