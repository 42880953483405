import SvgIcon from '@/components/SvgIcon'

const registerSvgIcon = (app) => {
  app.component('svg-icon', SvgIcon)

  const req = require.context('./svg', false, /\.svg$/)
  const requireAll = requireContext => requireContext.keys().map(requireContext)
  requireAll(req)
}

export default registerSvgIcon
